import React, { useEffect, useRef } from 'react';
import { Global } from '@emotion/core';
import styled from '@emotion/styled';
import { useColorMode } from 'theme-ui';
import Rellax from 'rellax';

import NavigationFooter from '@components/Navigation/Navigation.Footer';
import NavigationHeader from '@components/Navigation/Navigation.Header';
import ArticlesContextProvider from '../../sections/articles/Articles.List.Context';

import x1_light from '../../../../assets/img/1_1.png';
import x2_light from '../../../../assets/img/1_4.png';
import x1_dark from '../../../../assets/img/1_1_dark.png';
import x2_dark from '../../../../assets/img/1_4_dark.png';

import "../../../../css/typography.css";
import "./layout.css"

import { globalStyles } from '@styles';
import Index from '../../../../pages/about._waste';


/**
 * <Layout /> needs to wrap every page as it provides styles, navigation,
 * and the main structure of each page. Within Layout we have the <Container />
 "* which hides a lot of the mess we need to create our Desktop and Mobile experiences."
 */
const Layout: React.FC<{}> = ({ children, isArticle }) => {
  const [colorMode] = useColorMode();

  useEffect(() => {
    parent.postMessage({ theme: colorMode }, '*');
    // var rellax = new Rellax(".animate");
    // rellax.refresh();
  }, [colorMode]);

  useEffect(() => {
    if(isArticle!==true){
      new Rellax(".animate");
    }
    // var rellax = new Rellax(".animate");
    console.log(isArticle,"isArticle");
  }, []);

  return (
    <ArticlesContextProvider>
      <Container>
        <Global styles={globalStyles} />
        <NavigationHeader />
        <div className="absolute above">
          <div data-rellax-speed="-10" className={"animate"} style={{position:"relative", top:"15vh" ,marginLeft: "70%"}} >
            <img style={{height:"40px" ,opacity:0.7}} src={colorMode==='dark' ? x1_light : x1_dark} alt="x" />
          </div>
          <div data-rellax-speed="-7" className={"animate"} style={{position:"relative", top:"50vh" ,marginLeft: "20%"}} >
            <img style={{height:"60px" ,opacity:0.7}} src={colorMode==='dark' ? x2_light : x2_dark} alt="x" />
          </div>
          <div data-rellax-speed="-7" className={"animate"} style={{position:"relative", top:"60vh" ,marginLeft: "90%"}} >
            <img style={{height:"20px" ,opacity:0.7}} src={colorMode==='dark' ? x2_light : x2_dark} alt="x" />
          </div>
          <div data-rellax-speed="-5" className={"animate"} style={{position:"fixed", bottom:"105vh" ,marginLeft: "14%"}} >
            <img style={{height:"80px" ,opacity:0.7}} src={colorMode==='dark' ? x1_light : x1_dark} alt="x" />
          </div>

          <div data-rellax-speed="-9" className={"animate"} style={{position:"relative", top:"30vh" ,marginLeft: "65%"}} >
            <img style={{height:"15px" ,opacity:0.7}} src={colorMode==='dark' ? x1_light : x1_dark} alt="x" />
          </div>
          {/* <div className="animate2" style={{position:"relative", top:"50vh" ,marginLeft: "30%"}} >
            <img style={{height:"60px" ,opacity:0.7}} src={colorMode==='dark' ? x2_light : x2_dark} alt="x" />
          </div> */}
          <div data-rellax-speed="-10" className={"animate"} style={{position:"relative", top:"20vh" ,marginLeft: "10%"}} >
            <img style={{height:"20px" ,opacity:0.7}} src={colorMode==='dark' ? x2_light : x2_dark} alt="x" />
          </div>
          <div data-rellax-speed="-5" className={"animate"} style={{position:"fixed", bottom:"50vh" ,marginLeft: "95%"}} >
            <img style={{height:"80px" ,opacity:0.7}} src={colorMode==='dark' ? x1_light : x1_dark} alt="x" />
          </div>
        </div>
        
        {children}
        <div style={{width:"100vw"}}>
          <div className="absolute below">
            <img className={"lowerIMG left"} style={{height:"200px" ,opacity:0.2}} src={colorMode==='dark' ? x1_light : x1_dark} alt="x" />
            <img className={"lowerIMG right"} style={{height:"350px" ,opacity:0.2}} src={colorMode==='dark' ? x1_light : x1_dark} alt="x" />
          </div>
          <NavigationFooter/>
        </div>
        
        
      </Container>
    </ArticlesContextProvider>
  );
}

export default Layout;

const Container = styled.div`
  position: relative;
  background: ${p => p.theme.colors.background};
  transition: ${p => p.theme.colorModeTransition};
  min-height: 100vh;
  overflow:hidden;
`;